<template>
  <div id="app">
    <Header></Header>
    <main id="bghome">
          <div id="fond">
            <transition name="fade" mode="out-in">
            <router-view></router-view>
            </transition>
          </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from './components/header.vue';
  import Footer from './components/footer.vue';
  export default {
    components: {
      Header,
      Footer
    }
  }
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
#bghome {
    margin: 0;
    background-image: url("../src/assets/bg213.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-attachment: fixed;
    background-size: contain;
    @media screen and (max-width: 979px){
        background-image: none;
    }
}
#fond {
    background-image: url("../src/assets/bg222.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-attachment: fixed;
    background-size: contain;
    height: 100%;
    padding-bottom: 50px;
    @media screen and (max-width: 979px){
        background-size: cover;
    }
}
body {
    margin: 0px;
    padding: 0px;
    font-family: 'Quicksand', sans-serif;
}
a {
    margin: 0;
    text-decoration: none;
    color: black;
    &:hover {
                text-decoration: none;
                color: black;
            }
    &:visited {
                text-decoration: none; 
                color: black;
                 }
} 
</style>