import Vue from 'vue'
import App from './App.vue'
import router from './router'
import SlitherSlider from 'slither-slider'
import vuetify from './plugins/vuetify';
import VueYouTubeEmbed from 'vue-youtube-embed';
import Meta from 'vue-meta';

Vue.use(Meta);

Vue.use(VueYouTubeEmbed)

Vue.use(SlitherSlider)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
