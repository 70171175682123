<template>
    <footer>
          <div class="footer__content">
            <div class="footer__column">
                <ul class="footer__list">
                  <li class="footer__link"><i class="fas fa-map-marker-alt footer__icon"></i><p class="footer__link__text">12 Avenue de Laubian, <br>40510 Seignosse</p></li>
                  <li class="footer__link"><a class="footer__link__text" href="mailto:angelique.montagner@notaires.fr"><i class="far fa-envelope footer__icon"></i>angelique.montagner@notaires.fr</a></li>
                  <li class="footer__link"><a class="footer__link__text" href="tel:+33558583515"><i class="fas fa-phone footer__icon"></i>05 58 58 35 15</a></li>
                </ul>
              <div class="footer__button">Contactez-nous</div>
            </div>
            <div class="footer__column__logo">
              <img class="footer__logo" src="../assets/notaire.webp" onerror="this.src='../img/notaire.png';" alt="Logo des Notaires">
            </div> 
            <div class="footer__column__links">
              <ul class="footer__list">
                <li><router-link class="footer__link" to="Mentionslegales">Mentions Légales</router-link></li>
                <li><router-link class="footer__link" to="Sitemap">Plan de site</router-link></li>
                <li><router-link class="footer__link" to="Privacy">CGU et Politique de Confidentialité</router-link></li>
                <li><a href="https://lmcreationsnumeriques.fr" class="footer__link">Site réalisé par <img class="footer__lm" src="../assets/LMMINIATURE.webp" onerror="this.src='../img/LMMINIATURE.png';" alt="Logo de LM Créations Numériques"></a></li>
                <li><a href="https://www.lostinlanddesign.com/" class="footer__link">Artwork par © Elodie Perrier - Lost In Land</a></li>
              </ul>
            </div>
        </div> 
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

footer {
	display: block;
	background-color: $tertiary-color;
	width: 100%;
}

.footer {
    &__list {
    list-style-type: none;
    @media screen and (max-width: 1095px){
        padding: 0;
        width: fit-content;
        margin: auto;
         }
    }
    &__content {
	width: 80%;
	display: flex;
	margin: auto;
	box-sizing: border-box;
    padding: 30px 50px;
    @media screen and (max-width: 1095px){
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 20px 50px;
         }
    }
    &__column {
    display: block;
	width: 34%; 
    text-align: left;
    @media screen and (max-width: 1095px){
        width: 100%;
        margin-top: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
        &__logo {
        display: block;
        width: 34%;
        text-align: center;
        @media screen and (max-width: 1095px) {
            display: none;
            }
        }
        &__links {
            display: block;
            width: 34%; 
            text-align: left;  
            @media screen and (max-width: 1095px){
                width: 100%;
                margin-top: 25px;
                }
            @media screen and (max-width: 400px){
            font-size: x-small;
        }
            }
    }
    &__link {
    display: flex;
	margin-bottom: 15px;
    text-decoration: none;
    color: black;
    flex-direction: row;
    align-items: center;
    &__text {
        margin: 0;
        text-decoration: none;
        color: black;
        @media screen and (max-width: 400px){
            font-size: x-small;
        }
        &:hover {
            text-decoration: none;
            color: black;
        }
        &:visited {
            text-decoration: none; 
            color: black;
             }
        }    
    }
    &__logo {
        width: 100px;
        margin-top: 40px;
    }
    &__icon {
        margin-right: 10px;
    }
    &__button {
        background-color: white;
        text-align: center;
        width: fit-content;
        padding: 15px;
        border-radius: 15px;
        margin: auto;
        transition: all 250ms;
        box-shadow: 0px 2px 0px 0px black;
        &:hover {
            background-color: lighten($color: $tertiary-color, $amount: 30);
            box-shadow: 0px 2px 5px 0px black;
        }
        &:focus {
            background-color: lighten($color: $tertiary-color, $amount: 30);
            box-shadow: 0px 2px 5px 0px black;
        }
        @media screen and (max-width: 1095px){
            box-sizing: border-box;
            margin-bottom: 5px;
            }
        @media screen and (max-width: 400px){
            font-size: x-small;
        }
        }
    &__lm {
        width: 20px;
        vertical-align: middle;
        margin-left: 5px;
    }
}

</style>